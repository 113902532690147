<template>
<section class="submenu-wrapper">
    <ul class="submenu-content">
        <li :class="['submenu-li',menuIndex==i?'submenu-li_act':'']" v-for="(item,i) in menu" :key="i" @click="changeItem(item,i)">
            <span class="name">{{item.title}}</span>
        </li>
    </ul>
</section>
</template>

<script>
export default {
    props:{
        menu: Array,
        childIndex: Number,
    },
    data(){
        return {
            menuIndex: 0,
        }
    },
    watch:{
        childIndex(val){
            this.menuIndex = val;
        },
        '$route'(newVal,oldVal){
            this.menu.forEach((item,i)=>{
                if(item.routeName == newVal.name){
                    this.menuIndex = i;
                }
            })
        }
    },
    methods:{
        changeItem(item,i) {
            console.log(this.$route);
            if(this.$route.name==item.routeName) return;
            this.menuIndex = i;
            this.$router.push({
                name:item.routeName
            });
            // this.$emit('menu-change',item);
        },
        resetIndex(){
            this.menuIndex = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';
.submenu-wrapper {
    width: 100px;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    text-align: center;
    margin-top: 2px;

    .submenu-content {
        line-height: 40px;
        font-size: 14px;
        cursor: pointer;

        .submenu-li_act {
            background: #F2F2F2;
            color: $baseColor;
        }
    }

}
</style>