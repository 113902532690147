<template>
  <section class="index-wrapper">
    <MenuTree
      @menu-change="menuChangeHandler"
      @change-index="changeIndexHandler"
    ></MenuTree>
    <div class="index-content">
      <!-- top-bar -->
      <div class="top-bar flex space-between">
        <p class="bar-title">{{ topTitle }}中心</p>
        <article class="user-info">
          <div class="info-item system-content">
            <span
              >系统环境：<strong>{{ env }}</strong></span
            >
            <span class="el-icon el-icon-caret-bottom"></span>

            <ul class="system-box">
              <li
                class="system-item"
                @click="changeSystem(item)"
                v-for="(item, i) in envList"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="info-item qrcode-content">
            <span class="v-m">小程序码</span>
            <img class="qrcode v-m" src="../assets/images/qrcode.png" alt="" />

            <div class="qrcode-box">
              <img :src="wxcode" alt="" class="qrcode-img" />
              <p>微信扫描登录小程序</p>
            </div>
          </div>
          <div class="info-item user-content">
            <img :src="UserInfo.headimgurl" alt="" class="pro v-m" />
            <span class="el-icon el-icon-caret-bottom"></span>

            <ul class="user-box">
              <li class="user-item user-base">
                <img :src="UserInfo.headimgurl" alt="" class="v-m" />
                <span>{{ UserInfo.nickName }}</span>
              </li>
              <li class="user-item border">
                <p>系统版本：{{ env }}</p>
                <p>使用期限：至 {{ softwareExpirationTime }}</p>
              </li>
              <!-- <li>
                            <span class="icon mtui-icon-qiehuan-"></span>
                            <span>切换版本</span>
                        </li> -->
              <li>
                <span class="icon mtui-icon-userManagement"></span>
                <span>用户管理</span>
              </li>
              <li @click="logoutHandler">
                <span class="icon mtui-icon-anquantuichu"></span>
                <span>退出登录</span>
              </li>
            </ul>
          </div>
        </article>
      </div>

      <div class="view-wrapper flex">
        <!--  submenu -->
        <SubMenu
          :menu="submenuData"
          :childIndex="childIndex"
          ref="submenu"
        ></SubMenu>
        <div class="view-content thumb-bar">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import menuTree from "@/components/Menu/index";
import subMenu from "@/components/submenu.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import CHAT from "@/utils/socket.js";
CHAT.init();
export default {
  data() {
    return {
      topTitle: "",
      submenuData: [],
      childIndex: -1,
    };
  },
  components: {
    MenuTree: menuTree,
    SubMenu: subMenu,
  },
  computed: {
    ...mapGetters({
      UserInfo: "auth/UserInfo",
      envList: "auth/envList",
      softwareExpirationTime: "auth/softwareExpirationTime",
    }),
    ...mapState({
      env: (state) => state.auth.env,
    }),
    ...mapState(["wxcode"]),
  },
  created() {
    // 获取小程序码
    this.$store.dispatch({ type: "GetWxcodeAsync" });
  },
  methods: {
    ...mapMutations("auth", ["setEnv"]),
    menuChangeHandler(data) {
      console.log(data);
      this.topTitle = data.title;
      this.submenuData = data.childrens;
      this.$nextTick(() => {
        this.$refs.submenu.resetIndex();
      });
    },
    changeIndexHandler(k) {
      console.log(k);
      setTimeout(() => {
        this.childIndex = k;
      }, 300);
    },
    changeSystem(env) {
      //  切换环境
      this.setEnv(env);
      window.location.reload();
    },
    logoutHandler() {
      this.$store.dispatch({
        type: "auth/Logout",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index-wrapper {
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
  display: flex;

  .index-content {
    flex: 1;
    background: #f5f5f5;
    overflow: hidden;
    .top-bar {
      height: 50px;
      line-height: 50px;
      padding: 0 23px;
      font-size: 14px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .bar-title {
      font-weight: bold;
    }

    .user-info {
      font-size: 12px;
      color: #4a4a4a;
      cursor: pointer;

      strong {
        font-weight: normal;
        color: #666;
      }

      .info-item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
      .qrcode {
        width: 16px;
        height: 16px;
      }

      .pro {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }

    .system-content {
      &:hover {
        .system-box {
          top: 50px;
          opacity: 1;
        }
      }
    }

    .qrcode-content {
      &:hover {
        .qrcode-box {
          top: 50px;
          opacity: 1;
        }
      }
    }

    .system-box {
      position: absolute;
      top: -999px;
      right: 130px;
      z-index: 999;
      cursor: pointer;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
      border-radius: 2px;
      line-height: 30px;
      width: 180px;
      background: #fff;
      opacity: 0;
      transition: opacity 0.3s linear;

      .system-item {
        text-indent: 10px;
      }
      .system-item_act {
        background: #f2f2f2;
      }
    }

    .qrcode-box {
      position: absolute;
      top: -999px;
      right: 50px;
      z-index: 999;
      width: 180px;
      height: 180px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
      border-radius: 2px;
      opacity: 0;
      transition: opacity 0.3s linear;

      .qrcode-img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 15px auto 0;
      }

      p {
        line-height: 32px;
        text-align: center;
      }
    }

    .user-content {
      &:hover {
        .user-box {
          top: 50px;
          opacity: 1;
        }
      }
    }

    .user-box {
      position: absolute;
      right: 0;
      top: -999px;
      z-index: 999;
      width: 180px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
      border-radius: 2px;
      background: #fff;
      color: #333;
      line-height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.3s linear;

      .icon {
        padding-right: 10px;
      }

      .user-base {
        padding: 8px 0px;
        img {
          width: 24px;
          height: 24px;
          padding-right: 10px;
        }
      }

      .border {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }
    }
  }

  .view-wrapper {
    width: 100%;
    height: calc(100% - 50px);

    .view-content {
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      padding: 10px;
      overflow-y: scroll;
    }
  }
}
</style>
