import appConfig from "@/appConfig.js";
import { CACHE_KEY } from "@/utils/constant";

//  长连接获取认证信息， 每15分钟进行一次
const CHAT = {
  socket: null,
  init: function() {
    if (window.WebSocket) {
      CHAT.socket = new WebSocket(appConfig.SOCKET_URL);
      (CHAT.socket.onopen = function() {
        console.log("连接建立成功");
        // 主动发送一条数据进行拉取headers 头部授权码
        CHAT.socket.send("");
      }),
        (CHAT.socket.onmessage = function(e) {
          console.log("转为 JavaScript 对象", JSON.parse(e.data));
          window.localStorage.setItem(CACHE_KEY.AI_HA, e.data);
        }),
        (CHAT.socket.onclose = function() {
          console.log("连接关闭");
          CHAT.init();
        }),
        (CHAT.socket.onerror = function() {
          console.log("发生错误");
        });
    } else {
      alert("浏览器不支持WebSocket");
    }
  },
};

export default CHAT;
