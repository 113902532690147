<template>
  <section class="menu-wrapper">
    <h1 class="menu-title">
      <img src="../../assets/images/logo.png" alt="" class="logo" />
      <span>鸿亿寻客后台</span>
    </h1>
    <ul class="menu-content">
      <li
        :class="['menu-li', menuIndex == i ? 'menu-li_act' : '']"
        v-for="(item, i) in menuTree"
        :key="i"
        @click="changeItem(item, i)"
      >
        <span :class="['icon', item.icon]"></span>
        <span class="name">{{ item.title }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
import MENU_TREE from "./menuTree";
export default {
  data() {
    return {
      menuTree: MENU_TREE,
      menuIndex: 0,
    };
  },
  created() {
    let _this = this;
    this.menuTree.forEach((menu, i) => {
      if (menu.routeName == this.$route.meta.group) {
        this.menuIndex = i;
        this.$emit("menu-change", menu);
        menu.childrens.forEach((child, k) => {
          if (child.routeName == this.$route.name) {
            this.$emit("change-index", k);
          }
        });
      }
    });
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      // console.log(from);
      this.menuTree.forEach((menu, i) => {
        if (menu.routeName == to.meta.group) {
          this.menuIndex = i;
          this.$emit("menu-change", menu);
          menu.childrens.forEach((child, k) => {
            if (child.routeName == to.name) {
              this.$emit("change-index", k);
            }
          });
        }
      });
    },
  },
  methods: {
    changeItem(item, i) {
      if (this.menuIndex == i) return;
      this.menuIndex = i;
      this.$emit("menu-change", item);
      let routeName =
        (item.childrens[0] && item.childrens[0].routeName) || "index";
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";
.menu-wrapper {
  width: 159px;
  height: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  color: #fff;
  background: #051829;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
  .menu-title {
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    * {
      display: inline-block;
      vertical-align: middle;
    }

    .logo {
      width: 22px;
      height: auto;
      margin-right: 7px;
    }
  }

  .menu-content {
    line-height: 40px;
    font-size: 14px;
    // padding-left: 19px;
    cursor: pointer;

    .icon {
      margin: 0 10px;
    }

    .menu-li_act {
      background: $baseColor;
    }
  }
}
</style>
