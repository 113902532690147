export default [
  {
    title: "订单",
    routeName: "order",
    icon: "mtui-icon-downloadUpload",
    childrens: [
      {
        title: "订单管理",
        routeName: "orderList",
      },
    ],
  },
  {
    title: "商品",
    routeName: "goods",
    icon: "mtui-icon-inventoryBinding",
    childrens: [
      {
        title: "商品管理",
        routeName: "goodManage",
      },
      {
        title: "商品分组",
        routeName: "goodGroup",
      },
      {
        title: "添加商品",
        routeName: "addGood",
      },
    ],
  },
  {
    title: "客户",
    routeName: "customer",
    icon: "mtui-icon-publicAddress_v",
    childrens: [
      {
        title: "用户管理",
        routeName: "customerManager",
      },
    ],
  },
  {
    title: "动态",
    routeName: "dynamic",
    icon: "mtui-icon-drainageArticles",
    childrens: [
      {
        title: "动态管理",
        routeName: "dynamicManager",
      },
      {
        title: "发布动态",
        routeName: "releaseDynamics",
      },
      {
        title: "轮播图设置",
        routeName: "bannerSetting",
      },
      {
        title: "品牌介绍",
        routeName: "brandIntroduction",
      },
      {
        title: "产品介绍",
        routeName: "productIntroduction",
      },
    ],
  },
  {
    title: "设置",
    routeName: "setting",
    icon: "mtui-icon-rebateFrontEnd",
    childrens: [
      {
        title: "品牌信息",
        routeName: "brandInfo",
      },
      {
        title: "支付设置",
        routeName: "paySetting",
      },
      {
        title: "合作咨询",
        routeName: "feedback",
      },
    ],
  },
];
